<template>
  <!-- 服务地址栅栏 -->
  <div class="container">
    <a-page-header :title="title" @back="() => $router.go(-1)" />
    <div class="main-content">
      <div class="main-content-header">
        <div class="item">
          <div class="title">分布式（点对点）</div>
        </div>
        <div class="item">
          <a-button v-permission="'add'" @click="handleOpen">
            新增分布点
          </a-button>
        </div>
      </div>

      <my-table
        :columns="columns"
        :data-source="dataSource"
        :pagination="false"
        :loading="loading"
        :scroll="{ x: 1400 }"
      >
        <template slot="area" slot-scope="record">
          {{ record.province }}{{ record.city }}{{ record.district }}
        </template>
        <template slot="address" slot-scope="record">
          <a-tooltip placement="top" arrow-point-at-center>
            <template slot="title">
              <span>{{ record.address }}</span>
            </template>
            <div class="address">{{ record.address }}</div>
          </a-tooltip>
        </template>
        <template slot="distance" slot-scope="record">
          {{ record.distance }}km
        </template>
        <template slot="status" slot-scope="record">
          <a-badge
            :status="statuText[record.status].status"
            :text="statuText[record.status].text"
          />
        </template>
        <template slot="action" slot-scope="record">
          <a-button type="link" @click="handleEdit(record)">
            编辑
          </a-button>
          <a-button type="link" @click="setStatus(record.id, record.status)">
            <span v-if="record.status === 1">禁用</span>
            <span v-else>启用</span>
          </a-button>
          <a-button type="link" v-permission="'edit'">
            删除
          </a-button>
        </template>
      </my-table>
    </div>

    <div class="main-content main-content-2">
      <div class="main-content-header">
        <div class="item">
          <div class="title">区域性覆盖</div>
        </div>
        <div class="item">
          <a-button v-permission="'add'" @click="handleOpen2">
            添加更多区域
          </a-button>
        </div>
      </div>

      <my-table
        :columns="columns2"
        :data-source="dataSource2"
        :pagination="false"
        :loading="loading2"
      >
        <template slot="area" slot-scope="record">
          {{ record.province }}{{ record.city }}{{ record.district }}
        </template>
        <template slot="status" slot-scope="record">
          <a-badge
            :status="statuText[record.status].status"
            :text="statuText[record.status].text"
          />
        </template>
        <template slot="action" slot-scope="record">
          <a-button type="link" @click="handleChangeStatus(record.areaId)">
            禁用
          </a-button>
          <a-button type="link" v-permission="'edit'">
            删除
          </a-button>
        </template>
      </my-table>
    </div>

    <drawer :title="drawerTitle" :width="376" :visible="visible">
      <a-form-model ref="ruleForm" :model="form" :rules="rules">
        <a-form-model-item
          class="ant-form-item-input"
          ref="name"
          label="分布点别名"
          prop="name"
        >
          <a-input
            v-model="form.name"
            placeholder="请输入分布点别名"
            @blur="
              () => {
                $refs.name.onFieldBlur()
              }
            "
          />
        </a-form-model-item>
        <a-form-model-item label="选择省市区" prop="areaId">
          <a-cascader
            :options="cascaderAreaOptions"
            placeholder="请选择省市区"
            expand-trigger="hover"
            :field-names="fieldNames"
            v-model="form.areaId"
          />
        </a-form-model-item>
        <a-form-model-item
          ref="address"
          label="详细地址（街道、门牌号）"
          prop="address"
        >
          <a-select
            allow-clear
            show-search
            placeholder="请输入详细地址（街道、门牌号）"
            :default-active-first-option="false"
            :show-arrow="false"
            :filter-option="false"
            @search="handleAddressChange"
            @select="handleAddressSelect"
            :not-found-content="null"
            v-model="form.address"
          >
            <a-select-option
              v-for="(d, index) in addressReturn"
              :key="d.value + index"
            >
              {{ d.value }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="分布点经/纬度" prop="longiLatitue">
          <a-input
            v-model="form.longiLatitue"
            placeholder="请输入分布点经/纬度"
            disabled
          />
        </a-form-model-item>
        <a-form-model-item
          ref="distance"
          label="栅栏的方圆（公里数）"
          prop="distance"
        >
          <a-input
            v-model="form.distance"
            placeholder="请输入栅栏的方圆（公里数）"
            addon-after="km"
            @blur="
              () => {
                $refs.distance.onFieldBlur()
              }
            "
          />
        </a-form-model-item>
      </a-form-model>
      <div class="drawer-btn-wrap">
        <a-button @click="handleDrawerClose">
          取消
        </a-button>
        <a-button type="primary" @click="handleDrawerSubmit">
          提交
        </a-button>
      </div>
    </drawer>

    <drawer :title="drawerTitle" :width="376" :visible="visible2">
      <a-form-model ref="ruleForm2" :model="form2" :rules="rules2">
        <a-form-model-item label="选择省市区" prop="areaId">
          <a-cascader
            :options="cascaderAreaOptions"
            placeholder="请选择省市区"
            expand-trigger="hover"
            :field-names="fieldNames"
            v-model="form2.areaId"
          />
        </a-form-model-item>
      </a-form-model>
      <div class="drawer-btn-wrap">
        <a-button @click="handleDrawerClose2">
          取消
        </a-button>
        <a-button type="primary" @click="handleDrawerSubmit2">
          提交
        </a-button>
      </div>
    </drawer>

    <a-modal v-model="modalVisible" title="禁用 - 区域性" :closable="false">
      <div class="title">需要禁用的省市区</div>
      <a-cascader
        class="cascader"
        :options="cascaderAreaOptions"
        :field-names="fieldNames"
        v-model="areaIds"
        disabled
      />
      <a-alert type="warning" banner v-if="isAlert">
        <div class="a-alert" slot="message">
          <div class="left">
            系统检测到当前区域存在分布式（点对点）栅栏，是否一并禁用？
          </div>
          <a-button type="link">
            查看详情
          </a-button>
        </div>
      </a-alert>
      <template slot="footer">
        <a-button @click="handleModalCancel" :disabled="modalLoading">
          取消
        </a-button>
        <template v-if="isAlert">
          <a-button :loading="modalLoading" @click="handleModalConfirm(1)">
            仅禁用区域性
          </a-button>
          <a-button
            type="danger"
            :loading="modalLoading"
            @click="handleModalConfirm(2)"
          >
            全部禁用
          </a-button>
        </template>
        <a-button
          type="danger"
          :loading="modalLoading"
          @click="handleModalConfirm(1)"
          v-else
        >
          确认禁用
        </a-button>
      </template>
    </a-modal>
  </div>
</template>
<script>
import { Debounce } from '../../utils/public'
import drawer from '@/components/drawer'
let bcid = ""
let categoryId = '' // 业务id
let itemId = ''
export default {
  data() {
    return {
      title: '',
      statuText: [
        {
          status: 'default',
          text: '禁用'
        },
        {
          status: 'success',
          text: '启用'
        }
      ],
      dataSource: [],
      columns: [
        {
          title: '序号',
          width: 100,
          customRender: (text, record, index) => index + 1
        },
        {
          title: '分布点别名',
          width: 150,
          dataIndex: 'name'
        },
        {
          title: '省市区',
          width: 200,
          scopedSlots: { customRender: 'area' }
        },
        {
          title: '详细地址',
          width: 200,
          scopedSlots: { customRender: 'address' }
        },
        {
          title: '分布点经/纬度',
          width: 200,
          dataIndex: 'longiLatitue'
        },
        {
          title: '方圆（公里数）',
          width: 150,
          scopedSlots: { customRender: 'distance' }
        },
        {
          title: '状态',
          scopedSlots: { customRender: 'status' }
        },
        {
          title: '操作',
          width: 250,
          fixed: 'right',
          scopedSlots: {
            customRender: 'action'
          }
        }
      ],
      dataSource2: [],
      loading: true,
      columns2: [
        {
          title: '序号',
          width: 100,
          customRender: (text, record, index) => index + 1
        },
        {
          title: '省市区',
          scopedSlots: { customRender: 'area' },
          width: 200
        },
        {
          title: '状态',
          scopedSlots: { customRender: 'status' }
        },
        {
          title: '操作',
          width: 200,
          fixed: 'right',
          scopedSlots: {
            customRender: 'action'
          }
        }
      ],
      loading2: true,
      drawerTitle: '',
      visible: false,
      visible2: false,
      form: {
        bcid: "",
        name: '',
        categoryId: '',
        areaId: undefined,
        address: undefined,
        longiLatitue: '',
        distance: ''
      },
      form2: {
        bcid: "",
        categoryId: '',
        areaId: undefined
      },
      rules: {
        // 绑定地区
        name: [
          { required: true, message: '请输入分布点别名', trigger: 'blur' }
        ],
        address: [
          { required: true, message: '请输入详细地址', trigger: 'blur' }
        ],
        distance: [
          { required: true, message: '请输入栅栏的方圆', trigger: 'blur' }
        ],
        areaId: [{ required: true, message: '请选择省市区', trigger: 'change' }]
      },
      rules2: {
        areaId: [{ required: true, message: '请选择省市区', trigger: 'change' }]
      },
      cascaderAreaOptions: [],
      fieldNames: {
        label: 'name',
        value: 'id',
        children: 'children'
      },
      addressReturn: [],
      modalVisible: false,
      isAlert: false,
      modalLoading: false,
      areaIds: undefined
    }
  },
  components: {
    drawer
  },
  created() {
    // this.title = this.$getPageTitle()
    this.title = this.$route.query.name
    console.log("title",this.title)
    categoryId = this.$route.query.id
    bcid =  this.$route.query.bcid
    this.form.categoryId = categoryId
    this.form2.categoryId = categoryId
     this.form.bcid = bcid
    this.form2.bcid = bcid
    this.getServiceAddress()
  },
  methods: {
    getServiceAddress() {
      const data = {
        bcid: bcid,
        categoryId: categoryId
      }
      this.$axios.getServiceAddress(data).then((res) => {
        // console.log(res)
        this.dataSource = res.data.data.data
        this.loading = false
        this.getServiceArea()
      })
    },
    handleOpen() {
      // 新增分布点
      this.drawerTitle = '新增分布点'
      this.visible = true
      this.getArea()
    },
    handleEdit(e) {
      // 编辑
      this.drawerTitle = '编辑'
      this.form.name = e.name
      this.form.address = e.address
      this.form.longiLatitue = e.longiLatitue
      this.form.distance = e.distance
      this.address = e.address
      itemId = e.id
      this.visible = true
      this.getArea(1, e.areaId)
    },
    async getArea(type, id) {
      const area = await this.$axios.getArea()
      this.cascaderAreaOptions = area.nested
      if (type === 1 && id) {
        // 分布式
        this.form.areaId = this.findFatherId(area.tiled, id)
      }
      if (type === 2 && id) {
        // 区域
        this.areaIds = this.findFatherId(area.tiled, id)
        this.detectServiceArea()
      }
    },
    findFatherId(arr, id) {
      // 查找区域id的父级id
      const idArr = [id]
      const forFn = function(val) {
        arr.forEach((item) => {
          if (item.id === val) {
            idArr.push(item.id)
            forFn(item.parent_id)
          }
        })
      }
      forFn(id)
      idArr.reverse() // 颠倒排序
      return idArr
    },
    handleAddressChange: Debounce(function(e) {
      // 地址搜索
      if (!e) return
      const baseUrl = `https://apis.map.qq.com/ws/place/v1/search?keyword=${e}&key=${process.env.VUE_APP_QQMAPKEY}&output=jsonp&boundary=region(中国)`
      this.$jsonp(baseUrl)
        .then((res) => {
          // console.log(res)
          const data = res.data.map((v) => {
            if (v.address && v.title) {
              return {
                value: `${v.address}${v.title}`,
                longiLatitue: `${v.location.lng},${v.location.lat}`
              }
            }
          })
          this.addressReturn = data
        })
        .catch((err) => {
          console.log(err)
        })
    }, 500),
    handleAddressSelect(key) {
      // 监听地址选择
      const index = key.substring(key.length - 1)
      const item = this.addressReturn[index]
      this.form.longiLatitue = item.longiLatitue
    },
    handleDrawerClose() {
      // 关闭表单抽屉
      this.visible = false
      itemId = ''
      this.$refs.ruleForm.resetFields()
    },
    handleDrawerSubmit() {
      // 提交表单数据
     
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          const data = JSON.parse(JSON.stringify(this.form))
          const areaId = data.areaId
          data.areaId = areaId[areaId.length - 1]
          const address = this.form.address.substring(
            0,
            this.form.address.length - 1
          )
          data.address = address
          if (itemId) {
            data.id = itemId
            this.$axios.editServiceAddress(data).then(() => {
              this.$message.success('操作成功')
              this.handleDrawerClose()
              this.getServiceAddress()
            })
          } else {
            this.$axios.addServiceAddress(data).then(() => {
              this.$message.success('操作成功')
              this.handleDrawerClose()
              this.getServiceAddress()
            })
          }
        }
      })
    },
    setStatus(id, status) {
      // 设置状态
      let title = ''
      if (status === 1) {
        title = '确定禁用？'
        status = 0
      } else {
        title = '确定启用？'
        status = 1
      }
      this.$confirm({
        title: title,
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk: () => {
          const data = {
            bcid,
            id,
            status
          }
          this.$axios.changeServiceAddressStatus(data).then(() => {
            this.$message.success('操作成功')
            this.getServiceAddress()
          })
        }
      })
    },
    getServiceArea() {
      // 获取服务区域覆盖
      const data = {
        bcid: bcid,
        categoryId: categoryId
      }
      this.$axios.getServiceArea(data).then((res) => {
        this.dataSource2 = res.data.data.data
        this.loading2 = false
      })
    },
    handleOpen2() {
      // 新增区域性覆盖
      this.drawerTitle = '新增区域性覆盖'
      this.visible2 = true
      this.getArea()
    },
    handleDrawerClose2() {
      // 关闭表单抽屉
      this.visible2 = false
      this.$refs.ruleForm2.resetFields()
    },
    handleDrawerSubmit2() {
      // 提交表单数据
     
      this.$refs.ruleForm2.validate((valid) => {
        if (valid) {
          const data = JSON.parse(JSON.stringify(this.form2))
          const areaId = data.areaId
          data.areaId = areaId[areaId.length - 1]
          this.$axios.addServiceArea(data).then(() => {
            this.$message.success('操作成功')
            this.handleDrawerClose2()
            this.getServiceArea()
          })
        }
      })
    },
    handleModalCancel() {
      // 取消禁用区域
      this.modalVisible = false
      this.modalLoading = false
    },
    handleChangeStatus(id) {
      // 禁用区域
      this.modalVisible = true
      this.getArea(2, id)
    },
    detectServiceArea() {
      // 检测服务区域覆盖是否有分布点
      const data = {
        bcid: bcid,
        categoryId: categoryId,
        areaId: this.areaIds[this.areaIds.length - 1]
      }
      this.$axios.detectServiceArea(data).then((res) => {
        const lists = res.data.data
        this.isAlert = lists.length > 0 ? true : false
      })
    },
    handleModalConfirm(type) {
      // 区域禁用
      this.modalLoading = true
      const data = {
        bcid: bcid,
        categoryId: categoryId,
        areaId: this.areaIds[this.areaIds.length - 1],
        setType: type
      }
      this.$axios.changeServiceAreaStatus(data).then(() => {
        this.$message.success('操作成功')
        this.handleModalCancel()
        this.getServiceArea()
      })
    }
  }
}
</script>
<style scoped>
.main-content-2 {
  padding: 0 24px 24px 24px;
}

.address {
  width: 200;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.cascader {
  width: 100%;
  margin-top: 8px;
}

.a-alert {
  display: flex;
}

.ant-alert {
  margin-top: 16px;
}
</style>
